// Imports
// ------
import React from 'react';
import Cursor from '@global/cursors/home';
import ReactPlayer from 'react-player';
import * as easings from 'd3-ease';
import Body from '@utils/bodylock';
import { Transition, animated } from 'react-spring/renderprops';
import { Breakpoint } from 'react-socks';
import { observer } from 'mobx-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';


// Styles
// ------
import {
    Jacket,
    VideoJacket,
    FullscreenVideoJacket,
    FullscreenVideo,
    ProjectDetails,
    ProjectTitle,
    ProjectType,
    Close
} from './styles';

// Component
// ------
class Video extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            thumbnailPlaying: false,
            videoSelected: false,
            fullScreenPlaying: false
        }
    }

    toggleVideo = () => {
        this.setState({ thumbnailPlaying: !this.state.thumbnailPlaying });

        setTimeout(() => {
            this.setState({ playing: !this.state.playing });
        }, 50)
    }

    openFullVideo = () => {
        this.setState({
            playing: false,
            thumbnailPlaying: false,
            videoSelected: true,
            fullScreenPlaying: true
        });

        Body.lock();
    }

    closeFullVideo = () => {
        this.setState({
            playing: false,
            thumbnailPlaying: false,
            videoSelected: false,
            fullScreenPlaying: false
        });

        Body.unlock();
    }

    render() {
        const { src, thumb,  projectTitle, projectType } = this.props;

        return (
            <>
                <Jacket
                    onMouseEnter={this.toggleVideo}
                    onMouseLeave={this.toggleVideo}
                    onClick={this.openFullVideo}
                >
                    <LazyLoadImage
                        className="video-image"
                        alt={projectTitle}
                        src={thumb}
                        threshold={100}
                        effect="black-and-white"
                        scrollPosition={this.props.scrollPosition}
                        width={`100%`}
                        height={`100%`}
                    />

                    <ProjectDetails>
                        <ProjectTitle>{projectTitle}</ProjectTitle>
                        <ProjectType>{projectType}</ProjectType>
                    </ProjectDetails>
                    
                    <Breakpoint large up>
                        {this.state.thumbnailPlaying && (
                            <VideoJacket>
                                <ReactPlayer
                                    muted
                                    width='100%'
                                    height='100%'
                                    url={src}
                                    controls={false}
                                    playing={this.state.playing}
                                />
                            </VideoJacket>
                        )}
                    </Breakpoint>
                </Jacket>

                <Transition
                    native
                    items={this.state.videoSelected}
                    from={{
                        position: 'fixed',
                        zIndex: 13,
                        top: 0, left: 0, right: 0, bottom: 0,
                        opacity: 0,
                        backgroundColor: 'rgba(000,000,000,0.9)',
                    }}

                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                    config={{ duration: 500, easing: easings.easeCubic }}
                >
                    {items => items && (props =>
                        <animated.div style={props} onClick={this.closeFullVideo}>
                            <>
                                <Cursor />

                                <FullscreenVideoJacket className="close-trigger">
                                    <FullscreenVideo className="video-trigger">
                                        <ReactPlayer
                                            width='100%'
                                            height='100%'
                                            url={src}
                                            controls
                                            playing={this.state.fullScreenPlaying}
                                        />

                                        <Close>Close Video</Close>
                                    </FullscreenVideo>
                                </FullscreenVideoJacket>
                            </>
                        </animated.div>
                    )}
                </Transition>
            </>
        );
    }
}

export default observer(Video);