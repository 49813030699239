// Imports
// ------
import styled from 'styled-components';
import { Div, H2 } from '@reuse';

// Exports
// ------
export const Jacket = styled(Div)`
    background: ${props => props.theme.white};
`;

export const Content = styled(H2)`
    display: inline-flex;
    margin: 0 auto;

    text-align: center;
    color: ${props => props.theme.black};
    border-bottom: 6px solid ${props => props.theme.bc2};
`;