
// Imports
// ------
import React from 'react'
import gsap from "gsap";

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
class Cursor extends React.PureComponent {
    cursorRef = null;

    constructor(props) {
        super(props);
        this.flag = false;
    }

    componentDidMount() {
        if (window.matchMedia('(min-width: 1200px)').matches) {
            const $closeCursor = this.cursorRef;
            const $closeTrigger = document.querySelector('.close-trigger');
            const $videoTrigger = document.querySelector('.video-trigger');

            function moveCircle(e) {
                gsap.to($closeCursor, 0.3, {
                    css: {
                        opacity: 1,
                        left: e.layerX,
                        top: e.layerY,
                    }
                });
            }

            // Container
            // ------
            $closeTrigger.addEventListener('mouseover', () => {
                this.flag = true;
                gsap.to($closeCursor, 0.4, { scale: 1, opacity: 1 })
                $closeTrigger.addEventListener('mousemove', moveCircle);

            });

            $closeTrigger.addEventListener('mouseout', () => {
                this.flag = false;
                gsap.to($closeCursor, 0.4, { scale: 0.1, opacity: 0 })
            });


            $videoTrigger.addEventListener('mouseover', () => {
                $closeCursor.classList.add('hovering-video');
            });

            $videoTrigger.addEventListener('mouseout', () => {
                $closeCursor.classList.remove('hovering-video');
            });
        }
    }

    render() {
        return <Jacket ref={ref => this.cursorRef = ref} />
    }
}

export default React.memo(Cursor);