// Imports
// ------
import styled from 'styled-components';
import { Main } from '@reuse';
import { Row, Column } from '@grid';

// Exports
// ------
export const Jacket = styled(Main)`
    padding: 0.6rem;
    background-color: ${props => props.theme.white};

    > ${Row} {
        ${Column} {
            padding: 0.6rem;
        }
    }
`;

