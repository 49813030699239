// Imports
// ------
import styled from 'styled-components';
import { Div } from '@reuse';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-left: 1.2rem;
`;