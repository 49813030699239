// Imports
// ------
import React from 'react';
import { Row, Column } from '@grid';

// Styles
// ------
import { Jacket, Content, MainTitle } from './styles';

// Component
// ------
class Title extends React.PureComponent {
    render() {
        return (
            <Jacket pad>
                <Row isExpanded>
                    <Column medium={10} pushOnMedium={1} large={8} pushOnLarge={2} mpad>
                        <Content>
                            <MainTitle>{this.props.main}</MainTitle>
                        </Content>
                    </Column>
                </Row>
            </Jacket>
        );
    }
}

export default Title;
