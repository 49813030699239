// Imports
// ------
import React from 'react';
import { Row, Column } from '@grid';

// Styles
// ------
import { Jacket, Content } from './styles';

// Component
// ------
const Title = () => (
    <Jacket pad>
        <Row isExpanded>
            <Column small={12}>
                <Content>Contact</Content>
            </Column>
        </Row>
    </Jacket>
);

export default React.memo(Title);