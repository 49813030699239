// Imports
// ------
export const content = {
    title: `Privacy Policy`,
    updated: `Last updated February 21, 2020`,
    

    sections: [
        {
            body: [
                {
                    p: `Thank you for choosing to be part of our community at Rae and Eve Limited (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our notice, or our practices with regards to your personal information, please contact us at hello@raeandeve.com.`
                },
                {
                    p: `When you visit our website http://raeandeve.com/, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Sites and our services.
                    `
                },
                {
                    p: `This privacy notice applies to all information collected through our website (such as http://raeandeve.com/), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy notice as the "Services").`
                },
                {
                    p: `Please read this privacy notice carefully as it will help you make informed decisions about sharing your personal information with us.`
                },
            ]
            },
        {
            heading: `1. WHAT INFORMATION DO WE COLLECT?`,
            body: [
                {
                    p: `In Short:   Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our Services.`
                },
                {
                    p: `We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.`
                },
                {
                    p: `Like many businesses, we also collect information through cookies and similar technologies.`
                }
            ]
        },
        
        {
            heading: `2. WILL YOUR INFORMATION BE SHARED WITH ANYONE??`,
            body: [
                {
                    p: `We may process or share data based on the following legal basis:`
                },
                {
                    p: `Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.`
                },
                {
                    p: `Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.`
                },
                {
                    p: `Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.`
                },
                {
                    p: `Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).`
                },
                {
                    p: `Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.`
                },
                {
                    p: `More specifically, we may need to process your data or share your personal information in the following situations:`
                },
                {
                    p: `Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Services, which will enable them to collect data about how you interact with the Services over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.`
                },
                {
                    p: `Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.`
                },
                {
                    p: `Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit the Services. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.`
                },
            ]
        },

        {
            heading: `3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES??`,
            body: [
                {
                    p: `In Short:  We may use cookies and other tracking technologies to collect and store your information.`
                },
                {
                    p: `We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.`
                }
            ]
        },

        {
            heading: `4. DO WE USE GOOGLE MAPS?`,
            body: [
                {
                    p: `In Short:  Yes, we use Google Maps for the purpose of providing better service.`
                },
                {
                    p: `This website, mobile application, or Facebook application uses Google Maps APIs. You may find the Google Maps APIs Terms of Service here. To better understand Google’s Privacy Policy, please refer to this link.`
                },
                {
                    p: `By using our Maps API Implementation, you agree to be bound by Google’s Terms of Service.`
                },
            ]
        },
        
        {
            heading: `5. HOW LONG DO WE KEEP YOUR INFORMATION?`,
            body: [
                {
                    p: `In Short:  We may use cookies and other tracking technologies to collect and store your information.`
                },
                {
                    p: `When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.`
                },
            ]
        },
        
        {
            heading: `6. DO WE COLLECT INFORMATION FROM MINORS?`,
            body: [
                {
                    p: `In Short:  We do not knowingly collect data from or market to children under 18 years of age.`
                },
                {
                    p: `We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at hello@raeandeve.com.`
                }
            ]
        },
        
        {
            heading: `7. WHAT ARE YOUR PRIVACY RIGHTS?`,
            body: [
                {
                    p: `In Short:  In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.`
                },
                {
                    p: `In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.`
                },
                {
                    p: `If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.`
                },
                {
                    p: `If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.`
                },
            ]
        },
        
        {
            heading: `8. CONTROLS FOR DO-NOT-TRACK FEATURES`,
            body: [
                {
                    p: `Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.`
                }
            ]
        },

        {
            heading: `9. THIRD PARTY PROCESSORS`,
            body: [
                {
                    p: `Our carefully selected partners and service providers may process personal information about you on our behalf as described below:`
                },
                {
                    p: `Digital Marketing Service Providers`
                },
                {
                    p: `We periodically appoint digital marketing agents to conduct marketing activity on our behalf, such activity may result in the compliant processing of personal information.  Our appointed data processors include:`
                },
                {
                    p: `Prospect Global Ltd (trading as Sopro) Reg. UK Co. 09648733. You can contact Sopro and view their privacy policy here: http://sopro.io.  Sopro are registered with the ICO Reg: ZA346877 their Data Protection Officer can be emailed at: dpo@sopro.io`
                },
            ]
        },
        
        {
            heading: `10. DO WE MAKE UPDATES TO THIS POLICY?`,
            body: [
                {
                    p: `In Short:  Yes, we will update this policy as necessary to stay compliant with relevant laws.`
                },
                {
                    p: `We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.`
                }
            ]
        },
        
        {
            heading: `11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?`,
            body: [
                {
                    p: `If you have questions or comments about this policy, you may email us at hello@raeandeve.com`
                },
            ],
        },
    ]
}