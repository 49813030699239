// Imports
// ------
import React from 'react';
import content from './content';

// Styles
// ------
import {
    Jacket,
    NavList,
    NavItem,
    SubNavList,
    SubNavItem,
    MainLink,
    SubLink,
} from './styles';

// Component
// ------
const Navigation = () => (
    <Jacket>
        <NavList>
            {content.map(({ link ,label, subNav }) => (
                <NavItem key={label}>
                    <MainLink href={`/` + link}>{label}</MainLink>

                    {subNav && (
                        <SubNavList>
                            {subNav.map(({ link, label }) => (
                                <SubNavItem key={label}>
                                    <SubLink href={`/` + link}>{label}</SubLink>
                                </SubNavItem>
                            ))}
                        </SubNavList>
                    )}
                </NavItem>
            ))}
        </NavList>
    </Jacket>
);


export default React.memo(Navigation);