// Imports
// ------
import React from 'react';
import Rellax from 'rellax';
import SingleDirector from './singleDirector';
import Img from 'gatsby-image';
import { Row, Column } from '@grid';
import { useStaticQuery, graphql } from 'gatsby';

// Content
// ------
import content from './content';

// Styles
// ------
import {
    Jacket,
    BG,
    Content,
    Title,
    Body,
    BackgroundShape,
    DecoratorJacket
} from './styles';

// Components
// ------
class Directors extends React.PureComponent {
    componentDidMount() {
        const directorsParallax = new Rellax('.directors-plx', {
            wrapper: '#Directors',
            relativeToWrapper: true,
        });

        function breakpoint(x) {
            if (x.matches) { // If media query matches
                window.addEventListener('scroll', () => {
                    directorsParallax.refresh();
                });
            }
        }

        const x = window.matchMedia("(min-width: 1024px)");
        breakpoint(x);
        x.addListener(breakpoint);
    }

    render() {
        const title = "Directors";

        return (
            <>
                {/* Main Sector */}
                <Jacket pad id={title} className="directors-parallax">
                    <BG>
                        <Img fluid={this.props.data.bg.childImageSharp.fluid} alt="Background Image"/>
                    </BG>

                    {/* Main Title */}
                    <Row isCenter>
                        <Column small={12} medium={8} pushOnMedium={2} large={6} pushOnLarge={3} mpad>
                            <Content>
                                <BackgroundShape
                                    version="1.1"
                                    x="0px" y="0px"
                                    width="577.35px"
                                    height="500px"
                                    viewBox="0 0 577.35 500"
                                >
                                    <polygon points="433.01,0 144.34,0 0,250 144.34,500 433.01,500 577.35,250 " />
                                </BackgroundShape>
                                <Title light>{title}</Title>
                                <Body light>We have a roster of experienced directors across a spectrum of genres and mediums. From film to animation, motion capture to motion graphics their experience speaks for itself. Take a look at the portfolios below and give us a call if there is something specific you are looking for.</Body>
                            </Content>
                        </Column>
                    </Row>

                    {/* Visual Objects */}
                    <DecoratorJacket className="directors-plx" data-rellax-speed="7">
                        <Img fluid={this.props.data.left.childImageSharp.fluid} alt="Decorator Left"/>
                    </DecoratorJacket>

                    <DecoratorJacket className="directors-plx" data-rellax-speed="-7" right>
                        <Img fluid={this.props.data.right.childImageSharp.fluid} alt="Decorator Right" />
                    </DecoratorJacket>
                </Jacket>

                {/* Directors */}
                {content.map(({ name, anchor, description, showreel }) => (
                    <SingleDirector
                        key={name}
                        id={anchor}
                        name={name}
                        description={description}
                        showreel={showreel}
                    />
                ))}
            </>
        );  
    }
}

export default () => {
    const data = useStaticQuery(graphql`
        query directorsQuery {
            left: file(relativePath: {eq: "directors/directors-object-left.png"}) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }

            right: file(relativePath: {eq: "directors/directors-object-right.png"}) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }

            bg: file(relativePath: {eq: "directors/directors-bg.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1400, quality: 100) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
    `)

    return <Directors data={data} />
}