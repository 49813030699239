// Imports
// ------
import styled, { css } from 'styled-components';
import { Section, Div, H2, P } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    background-color: ${props => props.theme.white};
    overflow: hidden;
    width: 100%;

    ${breakup.uber`
        height: 100vh;
    `}
`;

const sharedFontStyles = css`
    position: relative;
    z-index: 2;
    color: ${props => props.theme.black};
    text-align: center;
`;

export const Title = styled(H2)`
    ${sharedFontStyles}
    display: inline-flex;
    margin: 0 auto 3.6rem;
    border-bottom: 6px solid ${props => props.theme.bc2};

    ${breakup.medium` margin: 0 auto 6rem; `}
`;

export const Body = styled(P)`
    ${sharedFontStyles}
    margin-bottom: 2.4rem;

    &:last-child { margin-bottom: 0; }
`;

export const DecoratorJacket = styled(Div)`
    position: absolute;
    z-index: 1;

    .gatsby-image-wrapper { height: 100%; }

    ${props => props.right ? css`
        right: -45%; bottom: 0;
        width: 88.5rem;
        height: 78.8rem;

        ${breakup.xxlarge` right: -50%; `}
        ${breakup.huge` right: -30%; `}
        ${breakup.uber` right: -24rem; `}
    ` : css`
        left: -75%; top: 0%;
        width: 102.7rem;
        height: 79.3rem;

        ${breakup.xxlarge` left: -50%; `}
        ${breakup.huge` left: -36%; `}
        ${breakup.uber` left: -40rem; `}
    `}
`;