// Imports
// ------
import styled from 'styled-components';
import { breakup } from '@break';
import { Div } from '@reuse';
import pin from '@images/contact/pin.png';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    z-index: 2;
    overflow: hidden;

    width: 100%;
    height: 50vh;
    background-color: ${props => props.theme.white};

    ${breakup.large` min-height: 80rem; `}
`;

export const PinJacket = styled(Div)`
    position: relative;
    width: 4.8rem;
    height: 6rem;

    background-image: url(${pin});
    background-repeat: no-repeat;
    background-size: contain;
`;