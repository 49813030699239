// Imports
// ------
import styled from 'styled-components';
import { Div, List, ListItem } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Div)`
    position: relative;
    z-index: 12;
`;

export const NavList = styled(List)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 1.2rem;
`;

export const SubNavList = styled(List)`
    display: none;
    position: absolute;
    top: 100%; left: -1.2rem;

    background: white;
    width: max-content;
    padding: 1.2rem;
`;

export const SubNavItem = styled(ListItem)`
    display: flex;
`;

export const SubLink = styled.a`
    ${props => props.theme.animate('.25s')}

    padding: 0.6rem;
    font-size: 1.4rem;
    font-family: ${props => props.theme.nav};
    color: ${props => props.theme.black};


    ${breakup.medium`
        padding: 0.6rem 1.2rem;
        font-size: 1.8rem;
    `}

    &:hover {
        color: ${props => props.theme.bc1};
    }
`;

export const NavItem = styled(ListItem)`
    position: relative;

    &:hover {
        ${breakup.large`
            > ${SubNavList} {
                display: flex;
                flex-flow: column;
            }
        `}
    }
`;


export const MainLink = styled.a`
    display: flex;
    position: relative;
    padding: 0.6rem;

    font-size: 1.3rem;
    font-family: ${props => props.theme.nav};
    color: ${props => props.theme.black};

    ${breakup.smedium`
        padding: 0.6rem 0.8rem;
        font-size: 1.4rem;
    `}

    ${breakup.large`
        padding: 0.6rem 1.2rem;
        font-size: 1.8rem;
    `}

    &:before {
        ${props => props.theme.animate('.25s')}
        content: '';
        position: absolute;
        bottom: -0rem; left: 50%;
        transform: translateX(-50%);

        width: 0;
        height: 2px;
        background-color: ${props => props.theme.bc1};
    }

    &:hover {
        &:before {
            ${breakup.large` width: calc(100% - 2.4rem); `}
        }
    }

    &:visited {
        color: ${props => props.theme.black};
    }
`;