// Imports
// ------
import styled, { css } from 'styled-components';
import { Div } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Thumbnail = styled.img`
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: all .25s ease-in-out;
`;

export const ProjectDetails = styled(Div)`
    position: absolute;
    z-index: 4;
    bottom: 0; left: 0; right: 0;
    background: rgba(000,000,000,0.5);

    display: flex;
    flex-flow: column;

    padding: 1.2rem;
    transition: all .25s ease-in-out;

    ${breakup.medium`
        padding: 2.4rem;
    `}
`;

const sharedProjectType = css`
    font-size: 1.8rem;
    font-family: ${props => props.theme.nav};
    line-height: 1;
    color: ${props => props.theme.white};
`;


export const ProjectTitle = styled.h3`
    ${sharedProjectType}
    margin-bottom: 1.2rem;
`;

export const ProjectType = styled.span`
    ${sharedProjectType}
`;

export const Jacket = styled(Div)`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 25vh;
    background: ${props => props.theme.black};
    cursor: pointer;

    &:before {
        content: "Loading";
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);

        color: white;
        font-family: ${props => props.theme.heading};
        font-size: 2.4rem;
    }

    ${breakup.large` height: 25vw; `}

    &:hover {
        .video-image {
            ${breakup.large`
                opacity: 0;
                transform: scale(1.1);
            `}
        }

        ${ProjectDetails} {
            ${breakup.large` opacity: 0; `}
        }
    }



    .video-image {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        transition: all .25s ease-in-out;
    }
`;

export const VideoJacket = styled(Div)`
    position: absolute;
    z-index: 2;
    top: 0; left: 0; right: 0; bottom: 0;

    .player-wrapper {
        position: relative;
        padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
    }
    
    .react-player {
        position: absolute;
        top: 0; left: 0;
    }

    video {
        object-fit: cover;
        outline: none;
    }
`;

export const FullscreenVideoJacket = styled(Div)`
    position: absolute;
    top: 0%; left: 0%; right: 0%; bottom: 0%;
`;

export const FullscreenVideo = styled(Div)`
    position: absolute;
    z-index: 5;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-height: 40rem;
    max-height: 40vw;
    max-width: 75vw;
`;

export const Close = styled.p`
    position: absolute;
    top: 110%; left: 50%;
    transform: translateX(-50%);

    color: white;
    pointer-events: none;
    font-size: 1.6rem;
    font-family: ${props => props.theme.body};
`;