// Imports
// ------
import styled from 'styled-components';
import { Section, Div } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 5;
`;

export const DecoratorJacket = styled(Div)`
    position: absolute;
    z-index: 0;
    top: 0%; left: 0%;
    width: 57.2rem;
    height: 36.6rem;

    ${breakup.large` left: -20%; `}
    ${breakup.xxlarge` left: 0%; `}
`;