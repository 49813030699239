// Imports
// ------
import styled from 'styled-components';
import { Section } from '@reuse';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    overflow: hidden;
    background: ${props => props.theme.white};
`;