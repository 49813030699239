// Imports
// ------
import styled from 'styled-components';
import Logo from '@global/logo';
import { Header } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Header)`
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 12;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 6rem;
    background-color: white;
`;

export const HeaderLogo = styled(Logo)`
    width: 7.2rem;
    transform: translateY(0.2rem);
    transition: all .1s linear;

    ${breakup.large`
        width: 12rem;
        transform: translateY(0rem);
    `}
`;

export const ScrollTop = styled.a`
    display: inline-flex;
    margin-left: 1.2rem;

    ${breakup.smedium` margin-left: 2.4rem; `}

    ${breakup.medium`
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
        margin-left: 0;
    `}

    &:hover {
        ${HeaderLogo} {
            ${breakup.large` width: 10rem; `}
        }
    }
`;