// Imports
// ------
import React from 'react';
import Cursor from '@global/cursors/home';
import ReactPlayer from 'react-player';
import * as easings from 'd3-ease';
import Body from '@utils/bodylock';
import { Row, Column } from '@grid';
import { Transition, animated } from 'react-spring/renderprops';
import { Breakpoint } from 'react-socks';
import { observer } from 'mobx-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Styles
// ------
import {
    Jacket,
    VideoContent,
    TextContent,
    Thumbnail,
    Title,
    Close,
    PlayerJacket,
    FullscreenVideoJacket,
    FullscreenVideo,
    Description,
    Paragraph
} from './styles';

// Component
// ------
class SingleWork extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            thumbnailPlaying: false,
            videoSelected: false,
            fullScreenPlaying: false
        }
    }

    // Turn video on / off
    thumbnailToggle = () => {
        this.setState({ thumbnailPlaying: !this.state.thumbnailPlaying });
        setTimeout(() => {
            this.setState({ playing: !this.state.playing });
        }, 50)
    }

    // Open fullscreen video
    openFullVideo = () => {
        this.setState({
            playing: false,
            thumbnailPlaying: false,
            videoSelected: true,
            fullScreenPlaying: true,
        });

        Body.lock();
    }

    // Close fullscreen video
    closeFullVideo = () => {
        this.setState({
            playing: false,
            thumbnailPlaying: false,
            videoSelected: false,
            fullScreenPlaying: false,
        });

        Body.unlock();
    }


    render() {
        const { number, thumb, thumbx2, projectTitle, projectDesc, src } = this.props;

        // Even
        const evenImageSettings = {
            pushOnMedium: 6,
            pushOnLarge: 1,
        };

        const evenTextSettings = {
            pullOnMediumOnly: 6,
            pushOnLarge: 2,
        };

        // Odd
        const oddImageSettings = {
            pushOnLarge: 5
        };

        const oddTextSettings = { 
            pullOnLarge: 5
        };

        return (
            <>
                {/* Page Content  */}
                <Jacket padBottom>
                    <Row isExpanded equal>
                        <Column small={12} medium={6} large={6} {...(number % 2 === 0) ? oddImageSettings :  evenImageSettings} mpad>
                            <VideoContent
                                onMouseEnter={this.thumbnailToggle}
                                onMouseLeave={this.thumbnailToggle}
                                onClick={this.openFullVideo}
                            >
                                <Thumbnail>
                                    <LazyLoadImage
                                        alt={projectTitle}
                                        src={thumb}
                                        srcSet={`${thumb} 1x, ${thumbx2} 2x`}
                                        delayTime={100}
                                        threshold={300}
                                        effect="black-and-white"
                                        scrollPosition={this.props.scrollPosition}
                                        width={`100%`}
                                        height={`100%`}
                                    />
                                </Thumbnail>

                                {/* Only show video on hover and mute */}
                                <Breakpoint large up>
                                    {this.state.thumbnailPlaying && (
                                        <PlayerJacket>
                                            <ReactPlayer
                                                muted
                                                width='100%'
                                                height='100%'
                                                url={src}
                                                controls={false}
                                                playing={this.state.playing}
                                            />
                                        </PlayerJacket>
                                    )}
                                </Breakpoint>
                            </VideoContent>
                        </Column>

                        <Column small={12} medium={6} large={3} {...(number % 2 === 0) ? oddTextSettings :  evenTextSettings}>
                            <TextContent mpad>
                                <Title>{projectTitle}</Title>
                                <Description>
                                    {projectDesc.map(( block, index ) => (
                                        <Paragraph key={index}>{block.paragraph}</Paragraph>
                                    ))}
                                </Description>
                            </TextContent>
                        </Column>
                    </Row>
                </Jacket>

                {/* Fullscreen Video only on click  */}
                <Transition
                    native
                    items={this.state.videoSelected}
                    from={{
                        position: 'fixed',
                        zIndex: 13,
                        top: 0, left: 0, right: 0, bottom: 0,
                        opacity: 0,
                        backgroundColor: 'rgba(000,000,000,0.9)',
                    }}

                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                    config={{ duration: 500, easing: easings.easeCubic }}
                >
                    {items => items && (props =>
                        <animated.div style={props} onClick={this.closeFullVideo}>
                            <>
                                <Cursor />

                                <FullscreenVideoJacket className="close-trigger">
                                    <Close>Close Video</Close>

                                    <FullscreenVideo className="video-trigger">
                                        <ReactPlayer
                                            width='100%'
                                            height='100%'
                                            url={src}
                                            controls
                                            playing={this.state.fullScreenPlaying}
                                        />
                                    </FullscreenVideo>
                                </FullscreenVideoJacket>
                            </>
                        </animated.div>
                    )}
                </Transition>
            </>
        );
    }
}

export default observer(SingleWork);