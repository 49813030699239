// Imports
// ------
import React from 'react';
import Header from '@global/header';
import Footer from '@global/footer';
import Hero from '@parts/hero';
import About from '@parts/about';
import LatestWork from '@parts/latestWork';
import Directors from '@parts/directors';
import Contact from '@parts/contact';
import Rellax from 'rellax';
import { trackWindowScroll } from 'react-lazy-load-image-component';

// Component
// ------
class IndexPage extends React.Component {
    componentDidMount() {
        const singleDirectorParallax = new Rellax('.single-director-plx', {
            wrapper: '.single-director',
            relativeToWrapper: true,
            center: true
        });
    
        function breakpoint(x) {
            if (x.matches) { // If media query matches
                window.addEventListener('scroll', () => {
                    singleDirectorParallax.refresh();
                });
            }
        }

        const x = window.matchMedia("(min-width: 1024px)");
        breakpoint(x);
        x.addListener(breakpoint);
    }

    render() {
        return (
            <>
                <Header />
                <Hero />
                <About />
                <LatestWork />
                <Directors />
                <Contact />
                <Footer />
            </>
        );
    }
}

export default trackWindowScroll(IndexPage);