// Imports
// ------
import { css } from "styled-components";
import { breakup, breakonly } from '@break';
import { isDefined } from './utils';

// Exports
// ------
const pushPoints = [
    { propInput: 'OnSmall', breakpoint: 'small' },
    { propInput: 'OnSmedium', breakpoint: 'smedium' },
    { propInput: 'OnMedium', breakpoint: 'medium' },
    { propInput: 'OnLarge', breakpoint: 'large' },
    { propInput: 'OnXlarge', breakpoint: 'xlarge' },
    { propInput: 'OnXxlarge', breakpoint: 'xxlarge' },
    { propInput: 'OnHuge', breakpoint: 'huge' },
    { propInput: 'OnUber', breakpoint: 'uber' },
]


export const push = pushPoints.map((item) => {
    const input = `push${item.propInput}`;
    
    return props => isDefined(props[input]) && css`
        ${breakup[item.breakpoint]`
            position: relative;
            left: calc(100% / 12 * ${props[input]});
        `}
    `
});

export const pushOnly = pushPoints.map((item) => {
    const input = `push${item.propInput}Only`
    
    return props => isDefined(props[input]) && css`
        ${breakonly[item.breakpoint]`
            position: relative;
            left: calc(100% / 12 * ${props[input]});
        `}
    `
});


export const pull = pushPoints.map((item) => {
    const input = `pull${item.propInput}`;
    
    return props => isDefined(props[input]) && css`
        ${breakup[item.breakpoint]`
            position: relative;
            left: calc(-100% / 12 * ${props[input]});
        `}
    `
});


export const pullOnly = pushPoints.map((item) => {
    const input = `pull${item.propInput}Only`;
    
    return props => isDefined(props[input]) && css`
        ${breakonly[item.breakpoint]`
            position: relative;
            left: calc(-100% / 12 * ${props[input]});
        `}
    `
});


export default push;