// Imports
// ------
import styled from 'styled-components';
import Icon from '@global/icons';
import { Div } from '@reuse';

// Exports
// ------
const iconSize = `2.4rem`;

export const StyledIcon = styled(Icon)`
    ${props => props.theme.animate('.25s')}

    width: ${iconSize};
    height: ${iconSize};
    fill: ${props => props.theme.black};
`;

export const Jacket = styled.a`
    display: flex;
    padding: 1.2rem;


    ${StyledIcon} {
        &:first-child,
        &:last-child {
            position: absolute;
            z-index: 1;
        }

        &:first-child { transform: translateY(0); }
        &:last-child { transform: translateY(120%); }
    }

    &:hover {
        ${StyledIcon} {
            fill: ${props => props.theme.bc1};

            &:first-child { transform: translateY(-120%); }
            &:last-child { transform: translateY(0); }
        }
    }
`;

export const Wrapper = styled(Div)`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;

    width: ${iconSize};
    height: ${iconSize};
`;