// Imports
// ------
import { css } from 'styled-components';
import { themeStyles } from './theme';

const sizes = {
    small: themeStyles.small,
    smedium: themeStyles.smedium,
    medium: themeStyles.medium,
    large: themeStyles.large,
    xlarge: themeStyles.xlarge,
    xxlarge: themeStyles.xxlarge,
    huge: themeStyles.huge,
    uber: themeStyles.uber
}

// Exports
// ------
const keys = Object.keys(sizes);

export const breakup = keys.reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${sizes[label]}) {
            ${css(...args)}
        }
    `
    return acc;
}, {})

export const breakdown = keys.reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${sizes[label]}) {
            ${css(...args)}
        }
    `
    return acc;
}, {})

export const breakonly = keys.reduce((acc, label) => {
    let nextIndex = keys.indexOf(label) + 1;

    acc[label] = (...args) => css`
        @media (min-width: ${sizes[label]}) and (max-width: ${sizes[keys[nextIndex]]}) {
            ${css(...args)}
        }
    `
    return acc;
}, {})
