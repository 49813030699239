// Imports
// ------
import styled from 'styled-components';
import { Section, Div, H2, P } from '@reuse';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 2;
    width: 100%;
`;

export const Content = styled(Div)`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const MainTitle = styled(H2)`
    position: relative;
    z-index: 2;
    display: inline-flex;
    border-bottom: 6px solid ${props => props.theme.white};
`;

export const Desc = styled(P)`
    text-align: center;
`;