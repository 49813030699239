// Imports
// ------

// Gregs
// ------
import bafta1Vid from '@videos/greg-clark/bafta-1.mp4';
import bafta1Thumb from '@videos/greg-clark/bafta-1.jpg';

import bafta2Vid from '@videos/greg-clark/bafta-2.mp4';
import bafta2Thumb from '@videos/greg-clark/bafta-2.jpg';

import bafta3Vid from '@videos/greg-clark/bafta-3.mp4';
import bafta3Thumb from '@videos/greg-clark/bafta-3.jpg';

import thePerfumeShopGreg1Vid from '@videos/greg-clark/the-perfume-shop-1.mp4';
import thePerfumeShopGreg1Thumb from '@videos/greg-clark/the-perfume-shop-1.jpg';

import thePerfumeShopGreg2Vid from '@videos/greg-clark/the-perfume-shop-2.mp4';
import thePerfumeShopGreg2Thumb from '@videos/greg-clark/the-perfume-shop-2.jpg';

import icelandVid from '@videos/greg-clark/iceland.mp4';
import icelandThumb from '@videos/greg-clark/iceland.jpg';

import harveysVid from '@videos/greg-clark/harveys.mp4';
import harveysThumb from '@videos/greg-clark/harveys.jpg';

import tombolaGreg1Vid from '@videos/greg-clark/tombola-1.mp4';
import tombolaGreg1Thumb from '@videos/greg-clark/tombola-1.jpg';

// Robs
// ------
import thePerfumeShopRob1Vid from '@videos/rob-sandom/the-perfume-shop-1.mp4';
import thePerfumeShopRob1Thumb from '@videos/rob-sandom/the-perfume-shop-1.jpg';

import thePerfumeShopRob2Vid from '@videos/rob-sandom/the-perfume-shop-2.mp4';
import thePerfumeShopRob2Thumb from '@videos/rob-sandom/the-perfume-shop-2.jpg';

import thePerfumeShopRob3Vid from '@videos/rob-sandom/the-perfume-shop-3.mp4';
import thePerfumeShopRob3Thumb from '@videos/rob-sandom/the-perfume-shop-3.jpg';

import thePerfumeShopRob4Vid from '@videos/rob-sandom/the-perfume-shop-4.mp4';
import thePerfumeShopRob4Thumb from '@videos/rob-sandom/the-perfume-shop-4.jpg';

import acmVid from '@videos/rob-sandom/acm.mp4';
import acmThumb from '@videos/rob-sandom/acm.jpg';

import elf1Vid from '@videos/rob-sandom/elf-1.mp4';
import elf1Thumb from '@videos/rob-sandom/elf-1.jpg';

import elf2Vid from '@videos/rob-sandom/elf-2.mp4';
import elf2Thumb from '@videos/rob-sandom/elf-2.jpg';

import weAreInternationalVid from '@videos/rob-sandom/we-are-international.mp4';
import weAreInternationalThumb from '@videos/rob-sandom/we-are-international.jpg';

// Exports
// ------
const content = [
    {
        anchor: `GregClark`,
        name: `Greg Clark`,
        description: `Although Greg comes from creative-driven backgrounds, at several points in his directing career he was producing more commercials per month than any other director in the country. This ability to handle big scale productions and tight deadlines together with his instinctive creativity has made him one of the most commercially sought after directors in the country.`,
        showreel: [
            {
                thumb: bafta1Thumb,
                projectTitle: `Bafta Audi`,
                projectType: `TV Commercial`,
                src: bafta1Vid,
            },
            {
                thumb: tombolaGreg1Thumb,
                projectTitle: `Tombola`,
                projectType: `TV Commercial`,
                src: tombolaGreg1Vid,
            },
            {
                thumb: bafta2Thumb,
                projectTitle: `Bafta Foundry`,
                projectType: `Trailer`,
                src: bafta2Vid,
            },
            {
                thumb: harveysThumb,
                projectTitle: `Harveys`,
                projectType: `TV Commercial`,
                src: harveysVid,
            },
            {
                thumb: bafta3Thumb,
                projectTitle: `Bafta Lens`,
                projectType: `TV Commercial`,
                src: bafta3Vid,
            },
            {
                thumb: thePerfumeShopGreg1Thumb,
                projectTitle: `The Perfume Shop`,
                projectType: `TV Commercial`,
                src: thePerfumeShopGreg1Vid,
            },
            {
                thumb: icelandThumb,
                projectTitle: `Iceland`,
                projectType: `TV Commercial`,
                src: icelandVid,
            },
            {
                thumb: thePerfumeShopGreg2Thumb,
                projectTitle: `The Perfume Shop`,
                projectType: `TV Commercial`,
                src: thePerfumeShopGreg2Vid,
            }
        ]
    },
    {
        anchor: `RobSandom`,
        name: `Rob Sandom`,
        description: `Rob's journey to directing comes from over 10 years creative direction for some of the country's most creative agencies. His eye for detail and passion for aesthetics sets him far apart from the norm. Applying this passion, from million pound motion capture animations to simple graphical commercials, has given Rob an enviable portfolio of work and unique set of skills.`,
        showreel: [
            {
                thumb: thePerfumeShopRob1Thumb,
                projectTitle: `The Perfume Shop`,
                projectType: `TV Commercial`,
                src: thePerfumeShopRob1Vid,
            },
            {
                thumb: thePerfumeShopRob2Thumb,
                projectTitle: `The Perfume Shop`,
                projectType: `TV Commercial`,
                src: thePerfumeShopRob2Vid,
            },
            {
                thumb: acmThumb,
                projectTitle: `Aliens Colonial Marines `,
                projectType: `Game Trailer`,
                src: acmVid,
            },
            {
                thumb: elf1Thumb,
                projectTitle: `Healthy Lungs for Life`,
                projectType: `TV Commercial`,
                src: elf1Vid,
            },
            {
                thumb: thePerfumeShopRob3Thumb,
                projectTitle: `The Perfume Shop`,
                projectType: `TV Commercial`,
                src: thePerfumeShopRob3Vid,
            },
            {
                thumb: elf2Thumb,
                projectTitle: `Healthy Lungs for Life`,
                projectType: `TV Commercial`,
                src: elf2Vid,
            },
            {
                thumb: thePerfumeShopRob4Thumb,
                projectTitle: `The Perfume Shop`,
                projectType: `TV Commercial`,
                src: thePerfumeShopRob4Vid,
            },
            {
                thumb: weAreInternationalThumb,
                projectTitle: `We Are International`,
                projectType: `TV Commercial`,
                src: weAreInternationalVid,
            }
        ]
    },
]

export default content;