// Imports
// ------
import Logo from '@global/logo';
import Social from '@global/social';
import styled, { css } from 'styled-components';
import { Footer, Div, P } from '@reuse';
import { pStyles } from '@theme/reusables/typographyStyles';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Footer)`
    position: relative;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    background: ${props => props.theme.white};
`;

const sharedTypeStyles = css`
    color: ${props => props.theme.black};
`;

export const Address = styled(Div)`
    text-align: center;
    margin: 3.6rem 0;

    ${breakup.large` margin: 4.8rem 0; `}
`;

export const Line = styled(P)`
    ${sharedTypeStyles}
`;

export const Contact = styled(Div)`
    display: flex;
    flex-flow: column;
    text-align: center;

    ${breakup.large` margin-bottom: 0; `}
`;


export const DecoratorJacket = styled(Div)`
    position: absolute;
    z-index: 2;
    top: 0%;
    right: 0%;
    width: 57rem;
    height: 39.6rem;
`;


export const FooterLogo = styled(Logo)`
    width: 12rem;
    
    ${breakup.large` width: 30.2rem; `}
`;

const sharedLinkStyles = css`
    ${pStyles}
    ${sharedTypeStyles}
    transition: all .25s ease-in-out;
    background: transparent;

    &:hover { color: ${props => props.theme.bc1}; }
`;


export const Link = styled.a`
    ${sharedLinkStyles}
`;

export const PrivacyToggle = styled.button`
    ${sharedLinkStyles}
`;

export const StyledSocial = styled(Social)`
    justify-content: center;
    margin-top: 3.6rem;
    margin-left: 0;
`;