// Imports
// ------
import { css } from 'styled-components';
import { breakup } from '@break';

// Exports
// ------
const visibility = css`
	${props => props.showOnSmedium && css`
		display: none !important;
		${breakup.smedium` display: flex !important; `}
	`}

	${props => props.showOnMedium && css`
		display: none !important;
		${breakup.medium` display: flex !important; `}
	`}

	${props => props.showOnLarge && css`
		display: none !important;
		${breakup.large` display: flex !important; `}
	`}

	${props => props.showOnXlarge && css`
		display: none !important;
		${breakup.xlarge` display: flex !important; `}
	`}

	${props => props.showOnXxlarge && css`
		display: none !important;
		${breakup.xxlarge` display: flex !important; `}
	`}
	
	${props => props.showOnHuge && css`
		display: none !important;
		${breakup.huge` display: flex !important; `}
	`}

	${props => props.showOnUber && css`
		display: none !important;
		${breakup.uber` display: flex !important; `}
	`}
`;

export default visibility;
