// Imports
// ------
import React from 'react';
import Rellax from 'rellax';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { content } from './content';
import { Row, Column } from '@grid';
import { Breakpoint } from 'react-socks';

// Styles
// ------
import { Jacket, Title, Body, DecoratorJacket } from './styles';

// Component
// ------
class About extends React.PureComponent {
    componentDidMount() {
        const aboutParallax = new Rellax('.about-plx', {
            wrapper: '#About',
            relativeToWrapper: true,
        });
        
        function breakpoint(x) {
            if (x.matches) { // If media query matches
                window.addEventListener('scroll', () => {
                    aboutParallax.refresh();
                });
            }
        }

        const x = window.matchMedia("(min-width: 1024px)");
        breakpoint(x);
        x.addListener(breakpoint);
    }

    render() {
        return (
            <Jacket pad id="About">
                {/* Textual Content */}
                <Row isCenter>
                    <Column small={12} medium={10} pushOnMedium={1} large={8} pushOnLarge={2} huge={6} pushOnHuge={3} mpad>
                        <Title>{content.title}</Title>
                        {content.body.map((block, index) => (
                            <Body key={index}>{block.paragraph}</Body>
                        ))}
                    </Column>
                </Row>

                {/* Visual Objects */}
                <Breakpoint xxlarge up>
                    <DecoratorJacket left className="about-plx" data-rellax-speed="4">
                        <Img fluid={this.props.data.left.childImageSharp.fluid} alt="Decorator Left"/>
                    </DecoratorJacket>

                    <DecoratorJacket right className="about-plx" data-rellax-speed="-4">
                        <Img fluid={this.props.data.right.childImageSharp.fluid} alt="Decorator Right"/>
                    </DecoratorJacket>
                </Breakpoint>
            </Jacket>
        );
    }
}

export default () => {
    const data = useStaticQuery(graphql`
        query aboutQuery {
            left: file(relativePath: {eq: "about/left.png"}) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }

            right: file(relativePath: {eq: "about/right.png"}) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
    `)

    return <About data={data} />
}