// Imports
// ------
import styled, { css } from 'styled-components';
import { Section, Div, H2, P } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 2;
    overflow: hidden;
    width: 100%;
    height: 100vh;

    ${breakup.smedium` height: 75vh; `}
    ${breakup.large` height: 100vh; `}
`;

export const BG = styled.div`
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 1;

    .gatsby-image-wrapper{  height: 100%; }
`;

export const Content = styled(Div)`
    position: relative;
    z-index: 3;
    text-align: center;
`;

export const Title = styled(H2)`
    position: relative;
    z-index: 2;
    display: inline-flex;
    margin: 0 auto 3.6rem;
    border-bottom: 6px solid ${props => props.theme.bc1};

    ${breakup.medium` margin: 0 auto 6rem; `}
`;

export const Body = styled(P)`
    position: relative;
    z-index: 2;
`;

export const BackgroundShape = styled.svg`
    position: absolute;
    z-index: 1;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%) scale(1.6);

    width: 100%;
    height: auto;
    object-fit: contain;

    ${breakup.large`
        transform: translate(-50%, -50%) scale(1.4);
    `}
`;

export const DecoratorJacket = styled(Div)`
    position: absolute;
    z-index: 2;
    mix-blend-mode: multiply;

    .gatsby-image-wrapper { height: 100%; }

    ${props => props.right ? css`
        bottom: 0%; right: -25%;
        width: 114.7rem;
        height: 73.2rem;
        ${breakup.huge`right: -24rem;`}
    ` : css`
        top: 0%; left: -25%;
        width: 114.1rem;
        height: 79.2rem;
        ${breakup.huge`left: -24rem;`}
    `}
`;