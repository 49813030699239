// Imports
// ------
import styled, { css } from "styled-components";
import { Section, Div } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
const sharedTransition = css`
    transition: all .5s ${props => props.theme.bezzy};
`;

const sharedHeight = css`
    ${sharedTransition}
    width: 100%;
    height: 50vh;

    ${breakup.medium` height: 75vh; `}
    ${breakup.large` height: 100vh; `}
`;

export const Jacket = styled(Section)`
    ${sharedHeight}
    position: fixed;
    top: 0; left: 0;
    z-index: -1;
    background: ${props => props.theme.black};
    margin-top: 6rem;

    ${breakup.large` margin-top: 0; `}
`;

export const Video = styled(Div)`
    ${sharedHeight}
    position: relative;
    overflow: hidden;

    .react-player,
    video {
        ${sharedTransition}
        position: absolute;
        top: 0; bottom: 0;

        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

export const Spacer = styled(Section)`
    ${sharedHeight}
    margin-top: 6rem;
    position: relative;
    pointer-events: none;
`;