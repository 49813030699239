// Imports
// ------
import React from 'react';
import SocialIcon from './socialIcon';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const socialArr = [
    {
        link: `https://www.instagram.com/raeandeve/`,
        type: `instagram`,
    },
    {
        link: `https://www.facebook.com/raeandeve/`,
        type: `facebook`,
    },
];

const Social = ({ className }) => (
    <Jacket className={className}>
        {socialArr.map(({ link, type }) => (
            <SocialIcon key={type} to={link} type={type} />
        ))}
    </Jacket>
);

export default React.memo(Social);