// Imports
// ------
import React from 'react';
import Breakpoint from 'react-socks';
import Social from '@global/social';
import Navigation from './nav';

// Styles
// ------
import { Jacket, HeaderLogo, ScrollTop } from './styles';

// Component
// ------
const Header = () => (
    <Jacket>
        <Breakpoint medium up>
            <Social />
        </Breakpoint>

        <ScrollTop href="/#top" aria-label="Home">
            <HeaderLogo />
        </ScrollTop>
        
        <Navigation />
    </Jacket>
);

export default React.memo(Header);