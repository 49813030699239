// Imports
// ------
import styled from 'styled-components';

// Exports
// ------
export const Jacket = styled.div`
    display: flex;
`;

export const Image = styled.svg`
    ${props => props.theme.animate('.25s')}
    fill: black;
    width: 100%;
    height: auto;
`