// Imports
// ------
import styled, { css } from 'styled-components';
import { Section, Div, H3  } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 4;
    width: 100%;
`;

const sharedStyles = css`
    position: relative;
    height: 100%;
`;

export const Thumbnail = styled(Div)`
    position: relative;
    z-index: 3;
    padding-top: 56.25%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    img {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: all .25s ease-in-out;
    }
`;

export const VideoContent = styled(Div)`
    ${sharedStyles}
    background: ${props => props.theme.black};


    &:hover {
        ${Thumbnail} {
            img {
                ${breakup.large`
                    opacity: 0;
                    transform: translate(-50%, -50%) scale(1.2);
                `}
            }
        }
    }
`

export const TextContent = styled(Div)`
    ${sharedStyles}
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    margin-top: 1.2rem;

    ${breakup.large`
        text-align: left;
        margin-top: 0;
    `}
`


export const PlayerJacket = styled(Div)`
    position: absolute;
    z-index: 2;
    top: 0; left: 0; right: 0; bottom: 0;
    

    .player-wrapper {
        position: relative;
        padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
    }
    
    .react-player {
        position: absolute;
        top: 0; left: 0;
    }

    video {
        object-fit: cover;
        outline: none;
    }
`;

export const FullscreenVideoJacket = styled(Div)`
    position: absolute;
    top: 0%; left: 0%; right: 0%; bottom: 0%;
    overflow: scroll;
`;

export const FullscreenVideo = styled(Div)`
    width: 100%;
    height: 100%;
    max-height: 40vw;
    margin-top: 6rem;
    margin-bottom: 3.6rem;

    ${breakup.large`
        position: absolute;
        z-index: 5;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        min-height: 40rem;
        max-width: 75vw;
        margin-top: 0rem;
        margin-bottom: 0rem;
    `}
`;

export const Title = styled(H3)`
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;

    ${breakup.large`
        margin-bottom: 2.4rem;
        margin-top: 0;
    `}
`;

export const Description = styled.div``;

export const Paragraph = styled.p`
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: ${props => props.theme.heading};
    color: ${props => props.theme.black};
    margin-bottom: 1.2rem;

    ${breakup.huge`
        font-size: 2.4rem;
        line-height: 3.6rem;
        letter-spacing: 0.1rem;
    `}

    &:last-child { margin-bottom: 0; }
`;

export const Close = styled.p`
    position: relative;
    top: 3.6rem; left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2.4rem;
    text-align: center;

    color: white;
    pointer-events: none;
    font-size: 1.6rem;
    font-family: ${props => props.theme.body};

    ${breakup.large` display: none; `}
`;