// Imports
// ------
import styled from 'styled-components';


// Imports
// ------
export const Jacket = styled.div`
    ${props => props.theme.talign}
    ${props => props.theme.sizer(`2.4rem`)}
    background: transparent;
    border-radius: 100%;
    pointer-events: none;
    z-index: 12;
    opacity: 0;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%; left: 50%;
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.white};
        transition: all .25s ease-in-out;
    }

    &:before { transform: translate(-50%, -50%) rotate(45deg); }
    &:after { transform: translate(-50%, -50%) rotate(-45deg); }

    &.hovering-video {
        &:before,
        &:after {
            opacity: 0;
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
`;