// Imports
// ------
import React from 'react';
import ReactPlayer from 'react-player';
import videoSrc from '@images/hero-video.mp4';

// Styles
// ------
import { Jacket, Video, Spacer } from './styles';

// Component
// ------
class Hero extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { playing: false }
    }

    componentDidMount() {
        this.setState({ playing: true });
    }

    render() {
        return (
            <>
                <Jacket id="top">
                    <Video>
                        <ReactPlayer
                            className='react-player'
                            url={videoSrc}
                            playing={this.state.playing}
                            playsinline
                            loop
                            muted
                            width='100%'
                            height='100%'
                        />
                    </Video>
                </Jacket>

                <Spacer />
            </>
        );
    }
}

export default React.memo(Hero);