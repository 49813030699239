// Imports
// ------
import { css } from "styled-components";
import { breakup } from '@break';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
const sharedColorStyles = css`
    color: ${props => props.light ? props.theme.white : props.theme.black};
`;

const sharedHeadingStyles = css`
    ${sharedColorStyles}
    font-weight: ${props => props.theme.reg};
    font-family: ${props => props.theme.heading};
    text-transform: uppercase;
    letter-spacing: 0.1em;
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
    ${sharedHeadingStyles}
    font-size: 3.6rem;
    line-height: 4.8rem;

    ${breakup.large`
        font-size: 7.2rem;
        line-height: 8.4rem;
    `}
`;

export const h2Styles = css`
    ${sharedHeadingStyles}
    font-size: 2.6rem;
    line-height: 4rem;

    ${breakup.large`
        font-size: 3.6rem;
        line-height: 4.8rem;
    `}
`;

export const h3Styles = css`
    ${sharedHeadingStyles}
    font-size: 2.2rem;
    line-height: 3.6rem;

    ${breakup.large`
        font-size: 3.2rem;
        line-height: 4rem;
    `}
`;

export const h4Styles = css`
    ${sharedHeadingStyles}
    font-size: 1.6rem;
    line-height: 2.8rem;

    ${breakup.large`
        font-size: 2rem;
        line-height: 3rem;
    `}
`;

export const h5Styles = css`
    ${sharedHeadingStyles}
    font-size: 2rem;
    line-height: 3.2rem;

    ${breakup.large`
        font-size: 2.4rem;
        line-height: 3.6rem;
    `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = css`
    ${sharedColorStyles}
    font-family: ${props => props.theme.body};
    font-weight: ${props => props.theme.reg};
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = css`
    ${sharedBodyStyles}
    font-size: 1.6rem;
    line-height: 2.6rem;

    ${breakup.large`
        font-size: 2.4rem;
        line-height: 3.6rem;
        letter-spacing: 0.05em;
    `}
`;

export const spanStyles = css`
    ${sharedBodyStyles}
    font-size: 1.6rem;
    line-height: 2.6rem;

    ${breakup.large`
        font-size: 1.8rem;
        line-height: 3.2rem;
    `}
`;

export const emStyles = css`
    ${sharedBodyStyles}
    display: block;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;

    ${breakup.large`
        font-weight: ${props => props.theme.med};
        font-size: 1.4rem;
        line-height: 1.8rem;
    `}
`;