// Imports
// ------
import React from 'react';
import Video from './video';
import { Row, Column } from '@grid';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Showreel = ({ videos }) => (
    <Jacket>
        <Row isExpanded isCollapsed>
            {videos.map(({ thumb, src, projectTitle, projectType }) => (
                <Column small={12} medium={6} large={3} key={src}>
                    <Video
                        thumb={thumb}
                        src={src}
                        projectTitle={projectTitle}
                        projectType={projectType}
                    />
                </Column>
            ))}
        </Row>
    </Jacket>
);

export default React.memo(Showreel);