// Imports
// ------
import styled, { css } from 'styled-components';
import { pStyles } from '@theme/reusables/typographyStyles';
import { Section, Div } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled(Section)`
    position: relative;
    width: 100%;
    background: ${props => props.theme.bc1};
    overflow: hidden;
`;

export const DecoratorJacket = styled(Div)`
    position: absolute;
    z-index: 0;
    top: 0%; left: 0%;
    width: 100%;
    height: 100%;
`;

export const Decorator = styled.div`
    width: 72rem;
    position: relative;

    ${props => props.altSide ? css`
        left: -100vw;
        ${breakup.xxlarge` left: -38vw; `}
        ${breakup.huge` left: -20vw; `}
    ` : css`
        right: -80vw;
        ${breakup.huge` right: -70vw; `}
    `}

    img {
        display: block;
        width: 100%;
        height: auto;
    }
`;


export const LoadMore = styled.div`
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    width: 100%;
    top: -4rem;
`;


export const Button = styled.button`
    ${pStyles}
    display: block;
    background: ${props => props.theme.white};
    color: ${props => props.theme.black};
    padding: 1.2rem 2.4rem;
    transition: all .25s ease-in-out;

    &:hover { opacity: 0.5; }
`;