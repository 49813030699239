// Imports
// ------
import tombola1Vid from '@videos/latest-work/tombola-1.mp4';
import tombola1Thumb from '@videos/latest-work/tombola-1.jpg';
import tombola1Thumbx2 from '@videos/latest-work/tombola-1.png';

import tombola2Vid from '@videos/latest-work/tombola-2.mp4';
import tombola2Thumb from '@videos/latest-work/tombola-2.jpg';
import tombola2Thumbx2 from '@videos/latest-work/tombola-2.png';

import tombola3Vid from '@videos/latest-work/tombola-3.mp4';
import tombola3Thumb from '@videos/latest-work/tombola-3.jpg';

import chesterZoo1Vid from '@videos/latest-work/chester-zoo-1.mp4';
import chesterZoo1Thumb from '@videos/latest-work/chester-zoo-1.jpg';
import chesterZoo1Thumbx2 from '@videos/latest-work/chester-zoo-1.png';

import chesterZoo2Vid from '@videos/latest-work/chester-zoo-2.mp4';
import chesterZoo2Thumb from '@videos/latest-work/chester-zoo-2.jpg';
import chesterZoo2Thumbx2 from '@videos/latest-work/chester-zoo-2.png';

import netflixVid from '@videos/latest-work/netflix.mp4';
import netflixThumb from '@videos/latest-work/netflix-thumb.jpg';
import netflixThumbx2 from '@videos/latest-work/netflix.jpg';

import netflixBTSVid from '@videos/latest-work/netflix-bts.mp4';
import netflixBTSThumb from '@videos/latest-work/netflix-bts-thumb.png';
import netflixBTSThumbx2 from '@videos/latest-work/netflix-bts.png';

import spaSeekersVid from '@videos/latest-work/spa-seekers.mp4';
import spaSeekersThumb from '@videos/latest-work/spa-seekers-thumb.jpg';
import spaSeekersThumbx2 from '@videos/latest-work/spa-seekers.jpg';

import IACTeaserVid from '@videos/latest-work/IAC-Teaser.mp4';
import IACTeaserThumb from '@videos/latest-work/IAC-Teaser-thumb.jpg';
import IACTeaserThumbx2 from '@videos/latest-work/IAC-Teaser.jpg';

import IACWhereAreWeVid from '@videos/latest-work/Wherearewe.mp4';
import IACWhereAreWeThumb from '@videos/latest-work/Wherearewe-thumb.jpg';
import IACWhereAreWeThumbx2 from '@videos/latest-work/Wherearewe.jpg';

// Exports
// ------
export const sectionHeader = {
    title: "Latest Films",
}

const content = [
    {
        thumb: IACTeaserThumb,
        thumbx2: IACTeaserThumbx2,
        projectTitle: `Location, location, location.`,
        projectType: `TV Content`,
        projectDesc: [
            {
                paragraph: `Things in 2020 got a little weird. Like a rather famous ITV reality show about celebrities trapped in a jungle, being filmed in a castle in Wales. So, in order for tombola arcade to seamlessly continue their Australian critter based sponsorship of I'm a Celeb’ we simply shipped the little bugs from Australia to Wales. Easy.`,
            },

            {
                paragraph: `Unfortunately, due to Covid and travel restrictions, the whole thing was shot in a studio in Manchester. Recreating the iconic jungle camp, down to the steel drainage holes in the canvas camp beds. The teaser ad ran for two weeks on the lead up to the show launch.`,
            },
        ],
        src: IACTeaserVid,
    },
    {
        thumb: IACWhereAreWeThumb,
        thumbx2: IACWhereAreWeThumbx2,
        projectTitle: `Mini Series`,
        projectType: `TV Content`,
        projectDesc: [
            {
                paragraph: `Once we had shipped our critters to Wales, we needed to recreate their new home of Gwrych Castle. We built a miniature castle set for the bugs to live in. Shooting both night and day scenes with all weather conditions. Bringing our CGI bugs back to life (with the addition of a Welsh snail) we produced over 30 idents, before the deadline of the show's launch in November. Head over to our instagram to see some production stills.`,
            },
        ],
        src: IACWhereAreWeVid,
    },

    {
        thumb: netflixBTSThumb,
        thumbx2: netflixBTSThumbx2,
        projectTitle: `Netflix Behind The Scenes`,
        projectType: `TV Content`,
        projectDesc: [
            {
                paragraph: `Before the world entered in to lockdown we were commissioned to produce behind the scenes footage and content to promote one of the latest titles on Netflix, The English Game.`,
            },

            {
                paragraph: `We created an exclusive behind the scenes video with interview pieces with the cast, crew and the show’s acclaimed writer, Julian Fellowes.`,
            },
        ],
        src: netflixBTSVid,
    },
    {
        thumb: netflixThumb,
        thumbx2: netflixThumbx2,
        projectTitle: `The English Game`,
        projectType: `TV Commercial`,
        projectDesc: [
            {
                paragraph: `To generate anticipation for the forthcoming new title on Netflix, The English Game written by Downton Abbey creator Julian Fellowes we produced a bespoke trailer that uses a host of key characters from the show to tell the story of the ‘two sides’: the two football teams and their determined captains, the mill workers and the Etonians, and the northerners and the southerners.`,
            },

        ],
        src: netflixVid,
    },
    {
        thumb: spaSeekersThumb,
        thumbx2: spaSeekersThumbx2,
        projectTitle: `Annnnnd relax`,
        projectType: `Brand Content`,
        projectDesc: [
            {
                paragraph: `Recently the R&E team had a spa day. Sadly the only people lazing around, prosecco in hand were our lovely artists. Who had to endure hours and hours of pampering and fine food in order for us to shoot these sumptuous brand films for the newly rebranded spa booking service SpaSeekers.`,
            },

        ],
        src: spaSeekersVid,
    },
    {
        thumb: chesterZoo1Thumb,
        thumbx2: chesterZoo1Thumbx2,
        projectTitle: `The Mane Event`,
        projectType: `TV Commercial`,
        projectDesc: [
            {
                paragraph: `Chester Zoo were moving their pride of Asiatic lions to a brand new and more immersive enclosure. Rae & Eve were commissioned to bring the experience to life in a fun and lively way; sounds quite simple, until you discover that the enclosure was still being built and had no lions in it.`,
            },

            {
                paragraph: `However, the creative called for the lions to be filmed very close-up in a documentary style. Add to this a bit of cinema magic (TM) to put a live lion in the (still under construction) enclosure and you would never know... until now.`,
            },
        ],
        src: chesterZoo1Vid,
    },
    {
        thumb: tombola1Thumb,
        thumbx2: tombola1Thumbx2,
        projectTitle: `I'm a Spider... Get Me Out of Here!`,
        projectType: `TV Commercial`,
        src: tombola1Vid,
        projectDesc: [
            {
                paragraph: `Online gaming brand, tombola arcade have sponsored ITV's I'm a Celebrity for the past 3 years. Rae & Eve were tasked with re-introducing the 'mini-camp' of bugs into the spotlight; starting with a TV teaser that ran in the week up to the show's launch and continuing with sponsorship bumpers throughout the series' 3-weeks on air.`,
            },

            {
                paragraph: `Disappointingly, the jungle sets were filmed in a studio in Manchester but thankfully the bugs are completely CGI. If they weren’t... GET US OUT OF HEEEEEEEEEEERE!`,
            },
        ],
    },
    {
        thumb: chesterZoo2Thumb,
        thumbx2: chesterZoo2Thumbx2,
        projectTitle: `The Tail of Madagascar`,
        projectType: `TV Commercial`,
        src: chesterZoo2Vid,
        projectDesc: [
            {
                paragraph: `To launch Chester Zoo's brand new Madagascar habitat, Rae & Eve were charged with telling the story through the attraction's main star, the characterful ring-tailed lemur. Showing all the colour and beauty of Madagascar and its indigenous inhabitants the animation takes us on a journey through the island's history and brings us to the fantastic new enclosure at Chester Zoo.`,
            },
        ],
    },
    {
        thumb: tombola2Thumb,
        thumbx2: tombola2Thumbx2,
        projectTitle: `It's a Big Deal!`,
        projectType: `TV Commercial`,
        src: tombola2Vid,
        projectDesc: [
            {
                paragraph: `To launch a raft of tombola arcade's new games we created a brand new 'brand' ad. Seamlessly going from one fun experience to the next. Bringing the players into the commercial by way of a hand shoot day. Filming each and every finger tap, swipe, flick and click we then add the animation to bring the games, the brand, and most importantly, the fun to life.`,
            },
        ],
    },
    {
        thumb: tombola3Thumb,
        thumbx2: tombola3Thumb,
        projectTitle: `Tombolé`,
        projectType: `TV Commercial`,
        src: tombola3Vid,
        projectDesc: [
            {
                paragraph: `In June we took the lovely tombola bingo players on a fun-filled few days to Birmingham. With our 20-strong art department we created bespoke locations for the players to hang out, play games and have lots and lots of fun. This fun is then filmed by four camera teams constantly across the days. Nothing is rehearsed, nothing is scripted, it's just: have fun and film it. So what you see in the ads is the real deal.`,
            },

            {
                paragraph: `This then forms the fantastic backdrop for us to add in 3D games and graphics to showcase tombola's unique games. Watch out for lots of versions of these throughout the year.`,
            }
        ],
    },
]

export default content;