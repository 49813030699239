// Imports
// ------
import React from 'react';
import Title from './title';
import Map from './map';
import Rellax from 'rellax';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Breakpoint } from 'react-socks';
// Styles
// ------
import { Jacket, DecoratorJacket } from './styles';

// Component
// ------
class Contact extends React.PureComponent {
    componentDidMount() {
        const contactParallax = new Rellax('.contact-plx', {
            wrapper: '#Contact',
            relativeToWrapper: true,
        });

        function breakpoint(x) {
            if (x.matches) { // If media query matches
                window.addEventListener('scroll', () => {
                    contactParallax.refresh();
                });
            }
        }

        const x = window.matchMedia("(min-width: 1024px)");
        breakpoint(x);
        x.addListener(breakpoint);
    }

    render() {
        return (
            <Jacket id="Contact">
                <Title />
                <Map />

                <Breakpoint large up>
                    <DecoratorJacket className="contact-plx" data-rellax-speed="3">
                        <Img fluid={this.props.data.file.childImageSharp.fluid} alt="Decorator Left" />
                    </DecoratorJacket>
                </Breakpoint>
            </Jacket>
        );
    }
}

export default () => {
    const data = useStaticQuery(graphql`
        query contactQuery {
            file(relativePath: {eq: "contact/contact-object-left.png"}) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
    `)

    return <Contact data={data} />
}