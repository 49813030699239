// Imports
// ------
import React from 'react';
import Showreel from './showreel';
import Header from './header';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const SingleDirector = ({ name, description, showreel, id }) => (
    <Jacket id={id}>
        <Header name={name} desc={description} />
        <Showreel videos={showreel} />
    </Jacket>
);

export default React.memo(SingleDirector);