// Imports
// ------


// Exports
// ------
const content = [
    {
        link: `#About`,
        label: `About`,
    },
    {
        link: `#Work`,
        label: `Work`,
    },
    {
        link: `#Directors`,
        label: `Directors`,
        subNav: [
            {
                link: `#GregClark`,
                label: `Greg Clark`
            },
            {
                link: `#RobSandom`,
                label: `Rob Sandom`
            },
        ]
    },
    {
        link: `#Contact`,
        label: `Contact`
    },
]

export default content;