// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket, Wrapper, StyledIcon } from './styles';

// Component
// ------
const SocialIcon = ({ to, type }) => (
    <Jacket href={to} target="_blank" rel="noopener noreferrer">
        <Wrapper>
            <StyledIcon type={type} />
            <StyledIcon type={type} />
        </Wrapper>
    </Jacket>
);

export default React.memo(SocialIcon);