// Exports
// ------
export const content = {
    title: `About Rae & Eve`,
    body: [
        {
            paragraph: `RAE & EVE exists to bring quality film and visual content to brands through the finest visionary talent and creative thinkers.`
        },
        {
            paragraph: `Our 10 years experience of creating the most high profile work across TV and online has seen us work with brands such as DFS, BAFTA, Audi, Tombola and Iceland.`
        },
        {
            paragraph: `The creation of RAE & EVE heralds a new era of production. Using the latest in production techniques to bring our high level of quality and experience to a wider range of established and aspiring brands.`
        },
        {
            paragraph: `Large & small, old & new, hungry & ambitious:`
        },
        {
            paragraph: `RAE & EVE.`
        },
    ]
}

export default content;