// Imports
// ------
import { css } from 'styled-components';

// Exports
// ------
const sharedButtonStyles = css`
    display: inline-block;
    position: relative;
`;

export const buttonStyles = css`
    ${sharedButtonStyles}
`;