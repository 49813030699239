// Imports
// ------
import React from 'react';
import Cursor from '@global/cursors/modal';
import Body from '@utils/bodylock';
import { Row, Column } from '@grid';
import { modalState } from '@states/modal';
import { observer } from 'mobx-react';
import { animated, Transition } from 'react-spring/renderprops';
import { Breakpoint } from 'react-socks';

// Styles
// ------
import { Jacket, ModalTrigger, ContentJacket, Content, Title, Updated, Heading, Paragraph, Section, MobileClose } from './styles';

// Content
// ------
import { content } from './content';

// Component
// ------
class Privacy extends React.PureComponent {
    toggleModal = () => {
        modalState.isOpen = !modalState.isOpen;
        Body.unlock();
    }

    render() {
        const privacyState = modalState.isOpen;

        return (
            <Transition
                native
                items={privacyState}
                from={{
                    position: 'fixed',
                    overflow: 'auto',
                    overflowX: 'hidden',
                    zIndex: '12',
                    top: '100%', left: '0%', right: '0%', bottom: '0%',
                }}
                enter={{ top: '0%' }}
                leave={{ top: '100%' }}
            >
                { privacyState => privacyState && (props => (
                    <animated.div style={props}>
                        <Jacket>
                            <ModalTrigger onClick={this.toggleModal} className="close-trigger"/>
                            <Cursor />

                            <Row isExpanded isCollapsed>
                                <Column medium={10} pushOnMedium={1} large={8} pushOnLarge={2} xxlarge={6} pushOnXxlarge={3} mpad>
                                    <ContentJacket>
                                        <Breakpoint medium down>
                                            <MobileClose onClick={this.toggleModal}>Close</MobileClose>
                                        </Breakpoint>

                                        <Row isExpanded isCollapsed>
                                            <Column>
                                                <Content className="video-trigger">
                                                    <Title>{content.title}</Title>
                                                    <Updated>{content.updated}</Updated>

                                                    {content.sections.map(({ heading, body }, index) => (
                                                        <Section key={index}>
                                                            <Heading>{heading}</Heading>
                                                            {body.map(({ p }, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Paragraph>{p}</Paragraph>
                                                                </React.Fragment>
                                                            ))}
                                                        </Section>
                                                    ))}
                                                </Content>
                                            </Column>
                                        </Row>
                                    </ContentJacket>
                                </Column>
                            </Row>
                        </Jacket>
                    </animated.div>
                ))}
            </Transition>
        );
    }
}

export default observer(Privacy);