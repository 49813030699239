// Imports
// ------
import styled, { css } from 'styled-components';
import { Header,  Div, H2, P } from '@reuse';
import { breakup } from '@break';


// Exports
// ------
export const Jacket = styled(Header)`
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: ${props => props.theme.bc1};
`;

export const Content = styled(Div)`
    position: relative;
    z-index: 2;
    text-align: center;
`;

export const Title = styled(H2)`
    display: inline-flex;
    margin: 0 auto 3.6rem;

    color: ${props => props.theme.black};
    border-bottom: 6px solid ${props => props.theme.white};

    ${breakup.large` margin: 0 auto 6rem; `}
`;

export const Desc = styled(P)`
    color: ${props => props.theme.black};
`;

export const DecoratorJacket = styled(Div)`
    position: absolute;
    z-index: 1;
    width: 65.1rem;
    height: 51.2rem;

    ${props => props.right ? css`
        bottom: 0; right: -20%;
        ${breakup.xxlarge` right: -25%;`}
        ${breakup.huge` right: 0;`}
    ` : css`
        top: 0%; left: -50%;
        ${breakup.xxlarge` left: -28%;`}
        ${breakup.huge` left: -12rem;`}
    `}
`;

