// Imports
// ------
import root from 'window-or-global';

// Exports
// ------
class Body {
    lock = () => {
        const bodyTag = document.querySelector('body');

        this.scrollY = root.scrollY;
        bodyTag.style.position = 'fixed';
        bodyTag.style.overflow = 'hidden';
        bodyTag.style.top = `${-this.scrollY}px`;
    }

    unlock = () => {
        const bodyTag = document.querySelector('body');

        bodyTag.style.position = 'initial';
        bodyTag.style.overflow = 'initial';
        root.scrollTo(0, this.scrollY);
    }
}

const inst = new Body();
export default inst;