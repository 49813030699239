// Imports
// ------
import React from 'react';
import Privacy from '@parts/privacy';
import Rellax from 'rellax';
import Body from '@utils/bodylock';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Breakpoint } from 'react-socks';
import { modalState } from '@states/modal';


// Styles
// ------
import {
    Jacket,
    FooterLogo,
    Address,
    Line,
    Contact,
    Link,
    PrivacyToggle,
    DecoratorJacket,
    StyledSocial
} from './styles';

// Component
// ------
class Footer extends React.PureComponent {
    togglePrivacy = () => {
        modalState.isOpen = !modalState.isOpen;
        Body.lock();
    }

    componentDidMount() {
        const footerParallax = new Rellax('.footer-plx', {
            wrapper: '#footer',
            relativeToWrapper: true,
        });

        function breakpoint(x) {
            if (x.matches) { // If media query matches
                window.addEventListener('scroll', () => {
                    footerParallax.refresh();
                });
            }
        }

        const x = window.matchMedia("(min-width: 1024px)");
        breakpoint(x);
        x.addListener(breakpoint);
    }

    render() {
        const email = `hello@raeandeve.com`;
        const phone = `+44 (0)1274 752287`;
        // eslint-disable-next-line global-require
        const phoneRaw = phone.replace(/\s|\(.*\)/g, "");

        return (
            <>
                <Jacket pad id="footer">
                    <FooterLogo />
                    <Address>
                        <Line>Hub 26, Hunsworth Lane</Line>
                        <Line>Cleckheaton BD19 4LN</Line>
                    </Address>

                    <Contact>
                        <Link href={`tel:${phoneRaw}`}>{phone}</Link>
                        <Link href={`mailto:${email}`}>{email}</Link>
                        <PrivacyToggle onClick={this.togglePrivacy}>Privacy Policy</PrivacyToggle>

                        <Breakpoint smedium down>
                            <StyledSocial />
                        </Breakpoint>
                    </Contact>

                    <Breakpoint large up>
                        <DecoratorJacket className="footer-plx" data-rellax-speed="4">
                            <Img fluid={this.props.data.file.childImageSharp.fluid} alt="Decorator Left" />
                        </DecoratorJacket>
                    </Breakpoint>
                </Jacket>

                <Privacy />
            </>
        );
    }
}

export default () => {
    const data = useStaticQuery(graphql`
        query footerQuery {
            file(relativePath: {eq: "footer/footer-object-right.png"}) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
    `)

    return <Footer data={data} />
}