// Imports
// ------
import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Column } from '@grid';
import { Breakpoint } from 'react-socks';

// Styles
// ------
import {
    Jacket,
    Content,
    Title,
    Desc,
    DecoratorJacket
} from './styles';

// Component
// ------
export default ({ name, desc }) => {
    const data = useStaticQuery(graphql`
        query directorsHeaderQuery {
            file(relativePath: {eq: "object-blue.png"}) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
    `)

    return(
        <Jacket pad className="single-director">
            {/* Content */}
            <Row>
                <Column small={12} medium={10} pushOnMedium={1} large={8} pushOnLarge={2} huge={6} pushOnHuge={3} mpad>
                    <Content>
                        <Title>{name}</Title>
                        <Desc>{desc}</Desc>
                    </Content>
                </Column>
            </Row>

            {/* Decorations */}
            <Breakpoint large up>
                <DecoratorJacket className="single-director-plx" data-rellax-speed="-4">
                    <Img fluid={data.file.childImageSharp.fluid} alt="Decorator Left" />
                </DecoratorJacket>
            
                <DecoratorJacket className="single-director-plx" data-rellax-speed="4" right>
                    <Img fluid={data.file.childImageSharp.fluid} alt="Decorator Right" />
                </DecoratorJacket>
            </Breakpoint>
        </Jacket>
    )
}