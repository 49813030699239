// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket, Image } from './styles';

// Component
// ------
const Logo = ({ className }) => (
    <Jacket className={className}>
        <Image width="122px" height="35px" viewBox="0 0 306.44 86.98" alt="Logo">
            <path d="M81.79,67.23l-0.38-0.81c-0.23-0.51-0.55-1.24-0.97-2.21c-0.41-0.94-3.08-7.33-3.08-7.33c-0.49-1.2-1-2.43-1.51-3.69H60.47
                    l-5.8,14.04H44.31L68.24,11l2.75,6.49l2.5,5.98c0.8,1.92,3.96,9.38,3.96,9.38c0.96,2.26,7.85,18.34,7.85,18.34l5.98,14.09
                    l0.81,1.95H81.79z M72.2,44.17c-0.34-0.8-2.52-6.13-2.52-6.13c-0.47-1.14-1.41-3.38-1.49-3.56l-3.99,9.69H72.2z M208.81,67.16
                    h-30.25c0,0,0,0,0,0h-5.63v-1.73c1.09-0.71,2.07-1.54,2.94-2.48c1.97-2.14,2.98-4.71,2.98-7.64c0-2.78-0.9-5.09-2.65-6.88
                    c-0.93-0.94-2.04-1.63-3.27-2.09v-3.06c0.46-0.15,0.92-0.29,1.41-0.44c3.27,1.49,5.52,3.41,6.71,5.71c1.34,2.6,2.04,5.14,2.12,7.55
                    v0.89c-0.01,0.21-0.05,0.4-0.06,0.61l0.06-0.07h25.65V67.16z M39.65,67.16l-1.43-2.18c-2.15-3.27-4.28-6.62-6.39-10.06
                    c-1.83-2.97-3.53-5.7-5.11-8.22c0.96-0.64,1.81-1.38,2.54-2.19c1.02-1.14,1.87-2.4,2.55-3.76c0.66-1.35,1.14-2.76,1.43-4.19
                    c0.29-1.4,0.43-2.85,0.43-4.3c0-2.67-0.47-5.07-1.4-7.15c-0.94-2.09-2.28-3.89-3.99-5.36c-1.7-1.45-3.73-2.55-6.02-3.27
                    c-2.27-0.72-4.79-1.08-7.48-1.08H0v51.75h10.24V49.68h6.06c0.69,1.08,6.95,10.92,7.92,12.44c0.97,1.54,1.91,3,2.81,4.4l0.41,0.64
                    H39.65z M10.24,39.91v-14.8h5.34c1.53,0,2.81,0.24,3.8,0.73c1.01,0.49,1.82,1.11,2.4,1.83c0.6,0.73,1.01,1.49,1.25,2.33
                    c0.26,0.88,0.38,1.7,0.38,2.41c0,0.71-0.13,1.54-0.39,2.45c-0.24,0.85-0.66,1.67-1.24,2.42c-0.56,0.73-1.37,1.35-2.39,1.86
                    c-1.01,0.51-2.32,0.76-3.89,0.76H10.24z M239.19,71.57l23.86-56.16h-10.36c0,0-10.82,26.22-13.53,32.79
                    c-0.98-2.37-13.53-32.79-13.53-32.79h-10.36L239.19,71.57z M306.44,67.16v-9.63h-25.65v-11.5h22.01V36.4h-22.01V24.9h24.71v-9.49
                    h-34.94v51.75H306.44z M205.16,36.4h-11.67c-2.17,0.87-5.08,1.91-8.85,3.16c1.94,1.57,3.54,3.4,4.79,5.47
                    c0.19,0.32,0.33,0.67,0.5,0.99h15.23V36.4z M131.84,40.57c-1.74,1.51-2.99,3.34-3.8,5.47h3.8V40.57z M109.84,57.53v-11.5h4.95
                    c0.58-1.22,1.25-2.39,2.04-3.49c1.73-2.4,3.64-4.42,5.7-6.14h-12.68v-11.5h14.4c-1.29-2.47-1.96-5.08-1.96-7.78
                    c0-0.58,0.07-1.15,0.13-1.72H99.6v51.75h14.34h0h18.77c0,0,0,0,0,0h2.77l0,0v-9.63l0,0H109.84z M187.57,15.41
                    c-1.72,1.21-3.7,1.85-5.92,1.85c-2.19,0-4.14-0.64-5.83-1.85h-2.9h0V28.2c3.8-1.14,7.49-2.43,10.23-3.29c0,0,0,0,0,0h24.71v-9.49
                    H187.57z"/>
            <path d="M115.76,54.3c0.44-3.78,1.64-7.09,3.65-9.88c2.58-3.58,5.51-6.3,8.8-8.16c3.28-1.86,6.33-3.16,9.14-3.89
                    c-3.85-1.65-6.79-3.85-8.82-6.58c-2.04-2.73-3.06-5.61-3.06-8.65c0-2.61,0.77-5.05,2.31-7.29c1.54-2.25,3.81-4.06,6.81-5.42
                    s6.61-2.05,10.84-2.05c5.27,0,9.36,1.19,12.28,3.58c1.46,1.19,2.56,2.51,3.29,3.95c0.73,1.44,1.09,2.84,1.09,4.18
                    c0,1.88-0.67,3.4-2.02,4.56s-2.88,1.73-4.61,1.73c-1.69,0-3.14-0.54-4.36-1.61c-1.21-1.08-1.81-2.52-1.81-4.33
                    c0-1.69,0.58-3.15,1.76-4.38c1.17-1.23,2.68-1.92,4.53-2.08c-0.35-1.04-1.35-1.93-3-2.68c-1.65-0.75-3.65-1.12-6-1.12
                    c-4.11,0-7.42,1.23-9.92,3.69s-3.75,5.92-3.75,10.38c0,5.27,1.99,9.75,6,13.44c2.72-0.46,5.17-0.69,7.32-0.69
                    c1.46,0,2.61,0.23,3.46,0.69s1.27,1.13,1.27,2.02c0,0.69-0.3,1.24-0.9,1.65c-0.59,0.4-1.4,0.6-2.39,0.6
                    c-1.15,0-2.39-0.21-3.69-0.63c-1.31-0.42-2.41-0.84-3.29-1.24c-0.88-0.41-1.44-0.65-1.68-0.72c-10.23,3.04-15.34,9.5-15.34,19.38
                    c0,0.54,0.08,1.05,0.11,1.58H115.76z M179.83,70.4c-2.45,2.16-5.44,3.89-8.96,5.19c-4.72,1.75-9.9,2.62-15.51,2.62
                    c-5.96,0-11.34-1.01-16.15-3.03c-2.95-1.24-5.46-2.86-7.65-4.79h-12.59c0.54,0.92,1.13,1.82,1.81,2.68
                    c3.48,4.46,8.14,7.89,13.96,10.29c5.83,2.4,12.18,3.61,19.06,3.61c7.31,0,13.69-1.33,19.15-3.98s9.65-6.14,12.55-10.47
                    c0.47-0.7,0.84-1.42,1.23-2.13H179.83z M203.4,7.47c-1.49-2.4-3.55-4.25-6.17-5.54C194.61,0.65,191.67,0,188.4,0
                    c-2.08,0-4.1,0.27-6.08,0.81c-1.98,0.54-3.63,1.42-4.93,2.65h0c-1.31,1.23-1.96,2.81-1.96,4.73l0,0l0,0c0,1.88,0.62,3.34,1.85,4.35
                    c1.23,1.02,2.69,1.53,4.38,1.53c1.81,0,3.36-0.56,4.67-1.67s1.97-2.48,1.97-4.1l0,0l0,0c0-1.08-0.29-2.12-0.87-3.14
                    c-0.58-1.02-1.38-1.8-2.42-2.33c0.35-0.69,1.52-1.04,3.52-1.04c2.42,0,4.68,0.53,6.77,1.59c2.1,1.06,3.78,2.5,5.05,4.32
                    c0.96,1.39,1.55,2.88,1.79,4.48h3.11C204.87,10.46,204.28,8.88,203.4,7.47z M172.17,31.75c-4.41,1.25-7.38,2.12-8.92,2.62
                    c-5.46,1.88-9.54,4.23-12.26,7.04c-2.71,2.81-4.06,6.21-4.06,10.21c0,3.08,0.72,5.65,2.16,7.73c1.44,2.08,3.28,3.61,5.54,4.61
                    c2.25,1,4.56,1.5,6.95,1.5c2.19,0,4.37-0.39,6.55-1.18c2.17-0.79,3.97-1.95,5.39-3.49c1.42-1.54,2.13-3.36,2.13-5.48
                    c0-1.92-0.58-3.47-1.73-4.64s-2.68-1.76-4.55-1.76c-1.85,0-3.39,0.57-4.61,1.7c-1.24,1.14-1.85,2.63-1.85,4.47
                    c0,1.81,0.54,3.19,1.64,4.15c1.09,0.96,2.39,1.58,3.89,1.85c-0.2,0.81-1.09,1.44-2.69,1.9c-1.59,0.46-3.07,0.69-4.41,0.69
                    c-1.58,0-3.21-0.38-4.9-1.12c-1.7-0.75-3.11-1.85-4.24-3.29c-1.14-1.44-1.7-3.12-1.7-5.05c0-2.35,0.9-4.33,2.71-5.94
                    c1.8-1.61,4.36-3.06,7.67-4.33c2.08-0.81,6.61-2.31,13.61-4.5c4.58,1.85,7.7,4.39,9.37,7.64c1.26,2.45,2.05,4.85,2.36,7.2h3.21
                    c-0.47-2.76-1.35-5.31-2.73-7.61c-2.1-3.48-5.36-6.14-9.78-7.99c8.92-2.81,14.77-4.86,17.53-6.17c2.38-1.19,4.4-2.66,6.11-4.39
                    h-17.05C180.42,29.14,176.31,30.58,172.17,31.75z"/>
        </Image>
    </Jacket>
);

export default React.memo(Logo);