// Imports
// ------
import styled, { css } from 'styled-components';
import { H2, H3, P } from '@reuse';
import { breakup } from '@break';

// Exports
// ------
export const Jacket = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
`;

export const ModalTrigger = styled.div`
    position: absolute;
    z-index: 1;
    top: 0; left: 0; right: 0;
    height: 100%;
    background: rgba(000,000,000,0.75);
`;

export const ContentJacket = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;

    ${breakup.smedium` margin: 1.2rem 0; `}
    ${breakup.large` margin: 25vh 0; `}
`;

export const Content = styled.div`
    position: relative;
    background: white;
    padding: 1.2rem;

    ${breakup.smedium` padding: 2.4rem; `}
    ${breakup.medium` padding: 3.6rem; `}
    ${breakup.large` padding: 4.8rem; `}
    ${breakup.xxlarge` padding: 6rem; `}
`;

export const Section  = styled.div`
    margin-bottom: 1.2rem;

    ${breakup.smedium` margin-bottom: 2.4rem; `}
    ${breakup.medium` margin-bottom: 3.6rem; `}
    ${breakup.large` margin-bottom: 4.8rem; `}
    ${breakup.xxlarge` margin-bottom: 6rem; `}

    &:last-child { margin-bottom: 0; }
`;

const sharedContentStyles = css`
    margin-bottom: 2.4rem;
`;

export const Title = styled(H2)`
    ${sharedContentStyles}
`;

export const Updated = styled(P)`
    ${sharedContentStyles}
    color: ${props => props.theme.bc2};
`;

export const Heading = styled(H3)`
    ${sharedContentStyles}
`;

export const Paragraph = styled(P)`
    ${sharedContentStyles}
`;

export const MobileClose = styled(P)`
    padding: 2.4rem;
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
`;